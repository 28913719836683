#okta-sign-in.auth-container.main-container {
    font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
    color: #424242 !important;
    margin-top: 25px;
      }
  #okta-login-container.login-container {
  margin-top: 15vh !important;
}

#widget-container_tfs #okta-sign-in.auth-container .button-primary {
    
    color: #fff !important;
    background-color: #13628D !important;
    background: linear-gradient(#13628D,#13628D) !important;
    border-color: #13628D !important;
    border-bottom-color: #13628D !important;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1) !important;
}

#widget-container_baldwin #okta-sign-in.auth-container .button-primary {
    
    color: #fff !important;
    background-color: #424242 !important;
    background: linear-gradient(#424242,#424242) !important;
    border-color: #424242 !important;
    border-bottom-color: #424242 !important;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1) !important;
}

#widget-container #okta-sign-in.auth-container .button-primary {
    color: #424242 !important;
    background-color: #ffb91d !important;
    background: linear-gradient(#ffb91d, #ffb40e) !important;
    border-color: #d08f00 !important;
    border-bottom-color: #c58800 !important;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .15), inset 0 1px 0 0 hsla(0, 0%, 100%, .1) !important;
   
}

#okta-sign-in .button,
#okta-sign-in .button.link-button {
    display: inline-block !important;
    height: 40px !important;
    line-height: 36px !important;
    padding: 0 15px !important;
    border: none !important;
    border-radius: 50px !important;
    font-size: 16px !important;
    box-sizing: border-box !important;
    -webkit-appearance: none !important;
}

#okta-sign-in .auth-footer {
    text-align: center;
    font-size: 12px;
}

.registration-label {
    text-align: center;
    white-space: pre;
}


.body__bg {
    background: #ffb91d;     
}

.login-wrap {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    background-color: #f9f9f9;
    color: #777;
    position: relative;
    overflow: auto;
    border-radius: 0;
    height: auto;
    margin: 42px auto 8px;
    width: 400px;
    min-width: 300px;
    box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.17);
}

.phlogo {
    position: relative;
    margin-top: 110px;
}

.phlogo img {
    display: block;
    max-width: 151px;
    width: 100%;
    margin: auto;
}

.ph-wiget-h2 {
    font-weight: 500;
    color: #424242;
    line-height: 0.5;
}

.widget-body-text {
    color: #424242;
    font-family: Roboto;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
}

.footer {
    text-align: center;
    margin-top: 50px;
}

.footer a {
    text-decoration: none;
    color: #424242;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.footer a:hover {
    text-decoration: underline;
}

.okta-sign-in-header.auth-header {
    display: none;
}

#okta-sign-in .o-form-head {
    font-size: 20px !important;
    line-height: 1.5 !important;
    margin-top: 10px !important;
    text-align: center !important;
    text-transform: uppercase !important;
}

#okta-sign-in.auth-container input[type="submit"],
#okta-sign-in.auth-container input[type="button"] {
    font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.o-form-select .okta-form-select-field{
    height: 40px;
    border: 1px solid #bbb !important;
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
}
.o-form-select .okta-form-select-field:hover{
    border-color: #888 !important;
}

.okta-form-select-field select{
    height: 100%;
    width: 100% !important;
    margin: 0 !important;
    padding: 6px 8px;
    line-height: 16px;
    border: none;
    background: transparent;
    box-sizing: border-box;
    font-family: proxima nova,montserrat,Arial,Helvetica,sans-serif;
    color: #5e5e5e;
    font-size: 14px;
}
