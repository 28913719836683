@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');

.registration-link {
    margin-left: 10px;
    color: #ffb91d;
}

.registration-container {
    margin-top: 30px;
}

button:disabled {
  opacity: 0.5;
 }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}
body {
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  line-height: 1;
}

h1 {
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 1.2;
}

h2 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, h2, p {
  margin-bottom: 1rem;
}

h3, h4 {
  margin-bottom: .75rem;
}

h5, h6 {
  margin-bottom: .5rem;
}

h3 {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1.2;
}

h4 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.2;
}

h5 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2
}

h6 {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.2
}

p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}

ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
:focus {
  outline: 0;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type=hidden] {
  display: none !important;
}
input[type=checkbox], input[type=radio] {
  border: 0 !important;
  margin: 0;
  padding: 0;
}

 .auth-footer {
    font-size: 12px;
    text-align: center; 
    color: #777 !important;
}

.signInBtn, 
.button-primary,
#okta-login-container #okta-sign-in.auth-container .button-primary {
    background: none;
    background-color: #ffb91d;
    border-color: #d08f00;
    border-bottom-color: #c58800;
    display: inline-block;
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
    box-shadow: none;
    color: #424242;
    line-height: 36px !important;
    padding: 0 30px !important;
    border-radius: 50px !important;
}

.signInBtn:hover, .button-primary:hover,
#okta-login-container #okta-sign-in.auth-container .button-primary:hover {
  background-color: #EAA200;
  text-decoration: none;
}

.email_verification-wrap {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  
  color: #000;
  position: relative;
  overflow: auto;
  border-radius: 0;
  height: auto;
  margin-top:10px;
  
  min-width: 300px;
  /* box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.17);
  background-color: #f9f9f9;
  -webkit-box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.17); */
}

.o-form-head-email {
    font-size: 20px;
    line-height: 1.5;
    margin-top: 10px;
    /* text-align: center; */
    text-transform: uppercase;
}

.widget-body-text-email {
    color: #424242;
    /* font-size: 13px; */
    line-height: 18px;
    /* text-align: center; */
}

#okta-sign-in .auth-content-email {
    /* padding: 60px 42px 20px; */
    transition: padding-top .4s;
}

.txtPassword {
    font-size: 14px;
    color: black;
    font-weight: bold;
}

.btn-primary-spacing 
{
  margin-left: 2px;
}

.inputBoxWidth{
  width:500px !important;
}

.showEmailValidationError{
  display: block !important;
}

.textAlignLeft{
  text-align: left !important;
  padding-bottom:20px !important;
}

.paddingTop30px{
  padding-top: 30px;
}

.paddingTop10px{
  padding-top: 10px;
}

.paddingLeft30px{
   padding-left: 30px;
}
.minWid100{
  min-width: 100px;
}

.yellow{
  height: 1px;
  background-color:#ffb91d;
}

.blue{
  height: 1px;
  background-color:#211F5F;
}

.confirmBtn{
    width:200px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #424242;
    background-color: #ffb91d;
    background: linear-gradient(#ffb91d,#ffb40e);
    border-color: #d08f00;
    border-bottom-color: #c58800;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.confirmBtn_tfs{
    width:200px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #fff;
    background-color: #13628D;
    background: linear-gradient(#13628D,#13628D);
    border-color: #13628D;
    border-bottom-color: #13628D;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}
.confirmBtn_updatePassword{
    width:250px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #424242;
    background-color: #ffb91d;
    background: linear-gradient(#ffb91d,#ffb40e);
    border-color: #d08f00;
    border-bottom-color: #c58800;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.confirmBtn_updatePassword_tfs{
    width:250px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #fff ! important;
    background-color: #13628D;
    background: linear-gradient(#13628D,#13628D);
    border-color: #13628D;
    border-bottom-color: #13628D;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.confirmBtn_updatePassword_baldwin{
  width:250px;
  display: inline-block;
  height: 40px;
  line-height: 36px;
  padding: 0 15px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  color: #fff ! important;
  background-color: #424242;
  background: linear-gradient(#424242,#424242);
  border-color: #424242;
  border-bottom-color: #424242;
  box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.inputEmailBox{
  height: 100%;
  width: 60%;
  margin: 0;
  padding: 6px 8px;
  line-height: 16px;
    /* border: none; */
    /* background: transparent; */
  box-sizing: border-box;
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0px solid #dee2e6 !important;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-top-color:#ffb91d;
    border-radius: 0.3rem;
    outline: 0;
}

.padding50px{
  padding: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
  
}

.paddingBottom20px{
  padding-bottom: 20px !important;
}

.paddingTop20px{
  padding-top: 20px !important;
}

.paddingRight10px{
  padding-right: 10px !important;
  
}

.confirmBtnModal{
    width:120px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #424242;
    background-color: #ffb91d;
    background: linear-gradient(#ffb91d,#ffb40e);
    border-color: #d08f00;
    border-bottom-color: #c58800;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.confirmBtnModal_tfs{
    width:120px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #fff !important;
    background-color: #13628D;
    background: linear-gradient(#13628D,#13628D);
    border-color: #13628D;
    border-bottom-color: #13628D;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.cancelBtn{
  background: none !important;
  border: 1px solid #ffb40c !important;
}

.cancelBtn_tfs{
  background: none !important;
  border: 1px solid #353535 !important;
  color:#000 !important
}

.applyFont{
  font-family: Roboto,Arial,Helvetica,sans-serif;
}

.padding40px{
  padding: 40px;
}

.modalLineColor_tfs .modal-content{
  border-top-color: #353535 !important;
}

.modalCancelBtn{
  margin-left:37px;
  margin-top:10px;
}

.borderWhite{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    background-color: #fff;
    position: relative;
    overflow: auto;
    border-radius: 0;
    height: auto;
    margin: 42px auto 8px;
    width: 400px;
    min-width: 300px;
    box-shadow: 0 3px 10px 5px rgba(0,0,0,.17);
    -webkit-box-shadow: 0 3px 10px 5px rgba(0,0,0,.17);
}

.logoLeft{
  position: relative;
  margin-top: 90px;
}

.logoLeft img{
  max-width : 151px;
  width:100%;
  margin:auto;
}

.content-wrapper {
  height: 100%;
  width: 100%;
  display: block;
}

.theme-parker {
  background: #FFB91D;
}

.theme-tfs,
.theme-baldwin {
  background: #E2E1DD;
}

.loader{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity:0.4;
  background: url('/assets/img/loading.gif') 
              50% 50% no-repeat;
}

.colorRed{
  color:red !important;
}

.formTitle{
  font-size: 20px !important;
  line-height: 1.5 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  text-align: center !important;
  text-transform: uppercase !important;
}


.regisFooter{
  text-align: center;
    font-size: 12px;
}

.formDiv {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
}
