@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap);
.registration-link {
    margin-left: 10px;
    color: #ffb91d;
}

.registration-container {
    margin-top: 30px;
}

button:disabled {
  opacity: 0.5;
 }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}
body {
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  line-height: 1;
}

h1 {
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 1.2;
}

h2 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, h2, p {
  margin-bottom: 1rem;
}

h3, h4 {
  margin-bottom: .75rem;
}

h5, h6 {
  margin-bottom: .5rem;
}

h3 {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1.2;
}

h4 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.2;
}

h5 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2
}

h6 {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.2
}

p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}

ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
:focus {
  outline: 0;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type=hidden] {
  display: none !important;
}
input[type=checkbox], input[type=radio] {
  border: 0 !important;
  margin: 0;
  padding: 0;
}

 .auth-footer {
    font-size: 12px;
    text-align: center; 
    color: #777 !important;
}

.signInBtn, 
.button-primary,
#okta-login-container #okta-sign-in.auth-container .button-primary {
    background: none;
    background-color: #ffb91d;
    border-color: #d08f00;
    border-bottom-color: #c58800;
    display: inline-block;
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
    box-shadow: none;
    color: #424242;
    line-height: 36px !important;
    padding: 0 30px !important;
    border-radius: 50px !important;
}

.signInBtn:hover, .button-primary:hover,
#okta-login-container #okta-sign-in.auth-container .button-primary:hover {
  background-color: #EAA200;
  text-decoration: none;
}

.email_verification-wrap {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  
  color: #000;
  position: relative;
  overflow: auto;
  border-radius: 0;
  height: auto;
  margin-top:10px;
  
  min-width: 300px;
  /* box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.17);
  background-color: #f9f9f9;
  -webkit-box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.17); */
}

.o-form-head-email {
    font-size: 20px;
    line-height: 1.5;
    margin-top: 10px;
    /* text-align: center; */
    text-transform: uppercase;
}

.widget-body-text-email {
    color: #424242;
    /* font-size: 13px; */
    line-height: 18px;
    /* text-align: center; */
}

#okta-sign-in .auth-content-email {
    /* padding: 60px 42px 20px; */
    transition: padding-top .4s;
}

.txtPassword {
    font-size: 14px;
    color: black;
    font-weight: bold;
}

.btn-primary-spacing 
{
  margin-left: 2px;
}

.inputBoxWidth{
  width:500px !important;
}

.showEmailValidationError{
  display: block !important;
}

.textAlignLeft{
  text-align: left !important;
  padding-bottom:20px !important;
}

.paddingTop30px{
  padding-top: 30px;
}

.paddingTop10px{
  padding-top: 10px;
}

.paddingLeft30px{
   padding-left: 30px;
}
.minWid100{
  min-width: 100px;
}

.yellow{
  height: 1px;
  background-color:#ffb91d;
}

.blue{
  height: 1px;
  background-color:#211F5F;
}

.confirmBtn{
    width:200px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #424242;
    background-color: #ffb91d;
    background: linear-gradient(#ffb91d,#ffb40e);
    border-color: #d08f00;
    border-bottom-color: #c58800;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.confirmBtn_tfs{
    width:200px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #fff;
    background-color: #13628D;
    background: linear-gradient(#13628D,#13628D);
    border-color: #13628D;
    border-bottom-color: #13628D;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}
.confirmBtn_updatePassword{
    width:250px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #424242;
    background-color: #ffb91d;
    background: linear-gradient(#ffb91d,#ffb40e);
    border-color: #d08f00;
    border-bottom-color: #c58800;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.confirmBtn_updatePassword_tfs{
    width:250px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #fff ! important;
    background-color: #13628D;
    background: linear-gradient(#13628D,#13628D);
    border-color: #13628D;
    border-bottom-color: #13628D;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.confirmBtn_updatePassword_baldwin{
  width:250px;
  display: inline-block;
  height: 40px;
  line-height: 36px;
  padding: 0 15px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  color: #fff ! important;
  background-color: #424242;
  background: linear-gradient(#424242,#424242);
  border-color: #424242;
  border-bottom-color: #424242;
  box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.inputEmailBox{
  height: 100%;
  width: 60%;
  margin: 0;
  padding: 6px 8px;
  line-height: 16px;
    /* border: none; */
    /* background: transparent; */
  box-sizing: border-box;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0px solid #dee2e6 !important;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-top-color:#ffb91d;
    border-radius: 0.3rem;
    outline: 0;
}

.padding50px{
  padding: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
  
}

.paddingBottom20px{
  padding-bottom: 20px !important;
}

.paddingTop20px{
  padding-top: 20px !important;
}

.paddingRight10px{
  padding-right: 10px !important;
  
}

.confirmBtnModal{
    width:120px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #424242;
    background-color: #ffb91d;
    background: linear-gradient(#ffb91d,#ffb40e);
    border-color: #d08f00;
    border-bottom-color: #c58800;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.confirmBtnModal_tfs{
    width:120px;
    display: inline-block;
    height: 40px;
    line-height: 36px;
    padding: 0 15px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #fff !important;
    background-color: #13628D;
    background: linear-gradient(#13628D,#13628D);
    border-color: #13628D;
    border-bottom-color: #13628D;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1);
}

.cancelBtn{
  background: none !important;
  border: 1px solid #ffb40c !important;
}

.cancelBtn_tfs{
  background: none !important;
  border: 1px solid #353535 !important;
  color:#000 !important
}

.applyFont{
  font-family: Roboto,Arial,Helvetica,sans-serif;
}

.padding40px{
  padding: 40px;
}

.modalLineColor_tfs .modal-content{
  border-top-color: #353535 !important;
}

.modalCancelBtn{
  margin-left:37px;
  margin-top:10px;
}

.borderWhite{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    background-color: #fff;
    position: relative;
    overflow: auto;
    border-radius: 0;
    height: auto;
    margin: 42px auto 8px;
    width: 400px;
    min-width: 300px;
    box-shadow: 0 3px 10px 5px rgba(0,0,0,.17);
    -webkit-box-shadow: 0 3px 10px 5px rgba(0,0,0,.17);
}

.logoLeft{
  position: relative;
  margin-top: 90px;
}

.logoLeft img{
  max-width : 151px;
  width:100%;
  margin:auto;
}

.content-wrapper {
  height: 100%;
  width: 100%;
  display: block;
}

.theme-parker {
  background: #FFB91D;
}

.theme-tfs,
.theme-baldwin {
  background: #E2E1DD;
}

.loader{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity:0.4;
  background: url('/assets/img/loading.gif') 
              50% 50% no-repeat;
}

.colorRed{
  color:red !important;
}

.formTitle{
  font-size: 20px !important;
  line-height: 1.5 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  text-align: center !important;
  text-transform: uppercase !important;
}


.regisFooter{
  text-align: center;
    font-size: 12px;
}

.formDiv {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
}

#okta-sign-in.auth-container.main-container {
    font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
    color: #424242 !important;
    margin-top: 25px;
      }
  #okta-login-container.login-container {
  margin-top: 15vh !important;
}

#widget-container_tfs #okta-sign-in.auth-container .button-primary {
    
    color: #fff !important;
    background-color: #13628D !important;
    background: linear-gradient(#13628D,#13628D) !important;
    border-color: #13628D !important;
    border-bottom-color: #13628D !important;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1) !important;
}

#widget-container_baldwin #okta-sign-in.auth-container .button-primary {
    
    color: #fff !important;
    background-color: #424242 !important;
    background: linear-gradient(#424242,#424242) !important;
    border-color: #424242 !important;
    border-bottom-color: #424242 !important;
    box-shadow: 0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1) !important;
}

#widget-container #okta-sign-in.auth-container .button-primary {
    color: #424242 !important;
    background-color: #ffb91d !important;
    background: linear-gradient(#ffb91d, #ffb40e) !important;
    border-color: #d08f00 !important;
    border-bottom-color: #c58800 !important;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .15), inset 0 1px 0 0 hsla(0, 0%, 100%, .1) !important;
   
}

#okta-sign-in .button,
#okta-sign-in .button.link-button {
    display: inline-block !important;
    height: 40px !important;
    line-height: 36px !important;
    padding: 0 15px !important;
    border: none !important;
    border-radius: 50px !important;
    font-size: 16px !important;
    box-sizing: border-box !important;
    -webkit-appearance: none !important;
}

#okta-sign-in .auth-footer {
    text-align: center;
    font-size: 12px;
}

.registration-label {
    text-align: center;
    white-space: pre;
}


.body__bg {
    background: #ffb91d;     
}

.login-wrap {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    background-color: #f9f9f9;
    color: #777;
    position: relative;
    overflow: auto;
    border-radius: 0;
    height: auto;
    margin: 42px auto 8px;
    width: 400px;
    min-width: 300px;
    box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.17);
}

.phlogo {
    position: relative;
    margin-top: 110px;
}

.phlogo img {
    display: block;
    max-width: 151px;
    width: 100%;
    margin: auto;
}

.ph-wiget-h2 {
    font-weight: 500;
    color: #424242;
    line-height: 0.5;
}

.widget-body-text {
    color: #424242;
    font-family: Roboto;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
}

.footer {
    text-align: center;
    margin-top: 50px;
}

.footer a {
    text-decoration: none;
    color: #424242;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.footer a:hover {
    text-decoration: underline;
}

.okta-sign-in-header.auth-header {
    display: none;
}

#okta-sign-in .o-form-head {
    font-size: 20px !important;
    line-height: 1.5 !important;
    margin-top: 10px !important;
    text-align: center !important;
    text-transform: uppercase !important;
}

#okta-sign-in.auth-container input[type="submit"],
#okta-sign-in.auth-container input[type="button"] {
    font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.o-form-select .okta-form-select-field{
    height: 40px;
    border: 1px solid #bbb !important;
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
}
.o-form-select .okta-form-select-field:hover{
    border-color: #888 !important;
}

.okta-form-select-field select{
    height: 100%;
    width: 100% !important;
    margin: 0 !important;
    padding: 6px 8px;
    line-height: 16px;
    border: none;
    background: transparent;
    box-sizing: border-box;
    font-family: proxima nova,montserrat,Arial,Helvetica,sans-serif;
    color: #5e5e5e;
    font-size: 14px;
}

.theme-myparker .my-parker-wrapper {
  font-family: 'Roboto';
}

.theme-myparker #okta-sign-in.auth-container.main-container {
  border: none;
  box-shadow: none;
  margin-top: 0;
}

.theme-myparker #okta-sign-in.auth-container.main-container {
  width: auto;
}

.theme-myparker #okta-login-container {
  background-color: #ffffff;
  border: solid 1px;
  border-color: #ddd #ddd #d8d8d8;
  width: 432px;
  min-width: 320px;
  max-width: 432px;
  margin: 0 auto;
  padding-bottom: 40px;
  padding-top: 40px;
}

.theme-myparker #okta-login-container {
  margin-top: 0 !important;
}

.theme-myparker .my-parker-content {
  padding: 8%;
}

.theme-myparker .my-parker-wrapper {
  min-height: 100vh;
}

.theme-myparker #okta-login-container .phlogo {
  margin-top: 20px;
}

.theme-myparker h4 {
  color: #bcbcbc;
  text-transform: uppercase;
}

.theme-myparker .footer {
  margin-top: 7px;
}

.theme-myparker .footer p{
  color: #BCBCBC;
}

.theme-myparker .footer p a, .theme-myparker #okta-sign-in.auth-container .link {
  color: #BCBCBC;
  font-size: 12px;
}

.theme-myparker .footer p a:hover,
.theme-myparker .footer p a:active,
.theme-myparker #okta-sign-in.auth-container .link:hover,
.theme-myparker #okta-sign-in.auth-container .link:active {
  color: #424242;
}

.theme-myparker #okta-sign-in.auth-container .auth-content {
  padding: 16px 35px 25px 35px;
}

.theme-myparker .my-parker-content .button-primary {
  margin-top: 1rem;
}

.theme-myparker .my-parker-content {
  margin-left: auto;
  margin-right: auto;
}
.welcome-greeting-wrapper{
  max-width: 50%;
}

.slideshow, .slideshow:after {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
}

.slideshow:after {
    background-color: #000;
    content: '';
    z-index: -1;
}

.slideshow {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
  
.slideshow li span {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-size: cover;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 36s;
}
  
.slideshow li:nth-child(1) span {
  background-image: url(/assets/slides/slide-1.jpg);
}

.slideshow li:nth-child(2) span {
  background-image: url(/assets/slides/slide-2.jpg);
  animation-delay: -24s;
}

.slideshow li:nth-child(3) span {
  background-image: url(/assets/slides/slide-3.jpg);
  animation-delay: -12s;
}

@keyframes fade {
  0% {opacity: 0;}
  20% {opacity: 1;}
  33% {opacity: 1;}
  53% {opacity: 0;}
  100% {opacity: 0;}
}

.theme-myparker h1 {
  color: #FFB91D;
  font-weight: bold;
}



@media only screen and (min-width: 768px) {
  .theme-myparker .my-parker-content {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .theme-myparker .my-parker-content {
    margin-left: 0;
  }
}

.my-parker-wrapper{
  background-image: url('/assets/img/myparker_background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container-fluid .row.my-parker-container{
  justify-content: space-evenly;
  min-height: 100vh;
}
.welcome-greeting-wrapper{
  background-color: rgba(0,0,0,0.6);
}
@media only screen and (max-width: 1024px) {
  .container-fluid .row.my-parker-container {
    flex-direction: column;
    justify-content: end;
  }

  .welcome-greeting-wrapper {
    max-width: 100%;
    text-align: center;
  }

  .container-fluid .row.my-parker-container .okta-login-wrapper {
    padding: 70px 0;
  }
}
.theme-myparker #okta-sign-in .o-form-head{
  text-transform: capitalize !important;
  font-size: 24px !important;
  font-weight: 500;
  color: #000000;
}

.theme-myparker #okta-sign-in .o-form-theme{
  padding: 0;
}
.theme-myparker #widget-container #okta-sign-in.auth-container .button-primary {
  color: #000000 !important;
  line-height: 21px !important;
}
.theme-myparker .phlogo img {
  height: 34px;
  width: 155px;
  max-width: unset;
}

@media only screen and (max-width: 375px) {
  .container-fluid .row.my-parker-container .okta-login-wrapper{
    width: 100%;
  }
  .theme-myparker #okta-login-container{
    width: 100%;
    min-width: unset;
    max-width: unset;
  }
  .welcome-greeting-wrapper {
    background-color: #000000;
  }
  .container-fluid .row.my-parker-container .okta-login-wrapper {
    padding: 0;
  }
  .my-parker-wrapper{
    background-image: unset;
  }
  .theme-myparker .phlogo img {
    height: 37px;
    width: 170px;
  }
}
@media only screen and (min-width: 1920px) {
  .theme-myparker h1 {
    font-size: 57.73px;
    line-height: 76px;
  }
  .theme-myparker .my-parker-content p {
    color: #FFFFFF;
    font-size: 27.65px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .theme-myparker h1 {
    font-size: 47.78px;
    line-height: 63px;
  }
  .theme-myparker .my-parker-content p {
    font-size: 23.4px;
    line-height: 28.08px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .theme-myparker h1 {
    font-size: 39.81px;
    line-height: 53px;
  }
  .theme-myparker .my-parker-content p {
    font-size: 23.4px;
    line-height: 28.08px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .theme-myparker h1 {
    font-size: 33.18px;
    line-height: 44px;
  }
  .theme-myparker .my-parker-content p {
    font-size: 19.2px;
    line-height: 23.04px;
  }
}

@media only screen and (max-width: 767px) {
  .theme-myparker h1 {
    font-size: 27.65px;
    line-height: 36px;
  }
  .theme-myparker .my-parker-content p {
    font-size: 19.2px;
    line-height: 23.04px;
  }

    .theme-myparker #okta-login-container {
      width: auto;
    }
}
.theme-myparker #okta-sign-in .o-form .input-fix input {
  line-height: 21px;
  font-size: 16px;
}

.theme-myparker #okta-sign-in .o-form-button-bar {
  padding: 0 0 45px;
}
.theme-myparker #okta-sign-in.auth-container.main-container .margin-btm-30 {
  margin-bottom: 15px;
}
.theme-myparker #okta-sign-in .o-form label{
  color: #424242;
}
.theme-myparker #okta-sign-in .o-form-head{
  margin: 0 0 24px !important;
}
