.theme-myparker .my-parker-wrapper {
  font-family: 'Roboto';
}

.theme-myparker #okta-sign-in.auth-container.main-container {
  border: none;
  box-shadow: none;
  margin-top: 0;
}

.theme-myparker #okta-sign-in.auth-container.main-container {
  width: auto;
}

.theme-myparker #okta-login-container {
  background-color: #ffffff;
  border: solid 1px;
  border-color: #ddd #ddd #d8d8d8;
  width: 432px;
  min-width: 320px;
  max-width: 432px;
  margin: 0 auto;
  padding-bottom: 40px;
  padding-top: 40px;
}

.theme-myparker #okta-login-container {
  margin-top: 0 !important;
}

.theme-myparker .my-parker-content {
  padding: 8%;
}

.theme-myparker .my-parker-wrapper {
  min-height: 100vh;
}

.theme-myparker #okta-login-container .phlogo {
  margin-top: 20px;
}

.theme-myparker h4 {
  color: #bcbcbc;
  text-transform: uppercase;
}

.theme-myparker .footer {
  margin-top: 7px;
}

.theme-myparker .footer p{
  color: #BCBCBC;
}

.theme-myparker .footer p a, .theme-myparker #okta-sign-in.auth-container .link {
  color: #BCBCBC;
  font-size: 12px;
}

.theme-myparker .footer p a:hover,
.theme-myparker .footer p a:active,
.theme-myparker #okta-sign-in.auth-container .link:hover,
.theme-myparker #okta-sign-in.auth-container .link:active {
  color: #424242;
}

.theme-myparker #okta-sign-in.auth-container .auth-content {
  padding: 16px 35px 25px 35px;
}

.theme-myparker .my-parker-content .button-primary {
  margin-top: 1rem;
}

.theme-myparker .my-parker-content {
  margin-left: auto;
  margin-right: auto;
}
.welcome-greeting-wrapper{
  max-width: 50%;
}

.slideshow, .slideshow:after {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
}

.slideshow:after {
    background-color: #000;
    content: '';
    z-index: -1;
}

.slideshow {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
  
.slideshow li span {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-size: cover;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 36s;
}
  
.slideshow li:nth-child(1) span {
  background-image: url(/assets/slides/slide-1.jpg);
}

.slideshow li:nth-child(2) span {
  background-image: url(/assets/slides/slide-2.jpg);
  animation-delay: -24s;
}

.slideshow li:nth-child(3) span {
  background-image: url(/assets/slides/slide-3.jpg);
  animation-delay: -12s;
}

@keyframes fade {
  0% {opacity: 0;}
  20% {opacity: 1;}
  33% {opacity: 1;}
  53% {opacity: 0;}
  100% {opacity: 0;}
}

.theme-myparker h1 {
  color: #FFB91D;
  font-weight: bold;
}



@media only screen and (min-width: 768px) {
  .theme-myparker .my-parker-content {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .theme-myparker .my-parker-content {
    margin-left: 0;
  }
}

.my-parker-wrapper{
  background-image: url('/assets/img/myparker_background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container-fluid .row.my-parker-container{
  justify-content: space-evenly;
  min-height: 100vh;
}
.welcome-greeting-wrapper{
  background-color: rgba(0,0,0,0.6);
}
@media only screen and (max-width: 1024px) {
  .container-fluid .row.my-parker-container {
    flex-direction: column;
    justify-content: end;
  }

  .welcome-greeting-wrapper {
    max-width: 100%;
    text-align: center;
  }

  .container-fluid .row.my-parker-container .okta-login-wrapper {
    padding: 70px 0;
  }
}
.theme-myparker #okta-sign-in .o-form-head{
  text-transform: capitalize !important;
  font-size: 24px !important;
  font-weight: 500;
  color: #000000;
}

.theme-myparker #okta-sign-in .o-form-theme{
  padding: 0;
}
.theme-myparker #widget-container #okta-sign-in.auth-container .button-primary {
  color: #000000 !important;
  line-height: 21px !important;
}
.theme-myparker .phlogo img {
  height: 34px;
  width: 155px;
  max-width: unset;
}

@media only screen and (max-width: 375px) {
  .container-fluid .row.my-parker-container .okta-login-wrapper{
    width: 100%;
  }
  .theme-myparker #okta-login-container{
    width: 100%;
    min-width: unset;
    max-width: unset;
  }
  .welcome-greeting-wrapper {
    background-color: #000000;
  }
  .container-fluid .row.my-parker-container .okta-login-wrapper {
    padding: 0;
  }
  .my-parker-wrapper{
    background-image: unset;
  }
  .theme-myparker .phlogo img {
    height: 37px;
    width: 170px;
  }
}
@media only screen and (min-width: 1920px) {
  .theme-myparker h1 {
    font-size: 57.73px;
    line-height: 76px;
  }
  .theme-myparker .my-parker-content p {
    color: #FFFFFF;
    font-size: 27.65px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .theme-myparker h1 {
    font-size: 47.78px;
    line-height: 63px;
  }
  .theme-myparker .my-parker-content p {
    font-size: 23.4px;
    line-height: 28.08px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .theme-myparker h1 {
    font-size: 39.81px;
    line-height: 53px;
  }
  .theme-myparker .my-parker-content p {
    font-size: 23.4px;
    line-height: 28.08px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .theme-myparker h1 {
    font-size: 33.18px;
    line-height: 44px;
  }
  .theme-myparker .my-parker-content p {
    font-size: 19.2px;
    line-height: 23.04px;
  }
}

@media only screen and (max-width: 767px) {
  .theme-myparker h1 {
    font-size: 27.65px;
    line-height: 36px;
  }
  .theme-myparker .my-parker-content p {
    font-size: 19.2px;
    line-height: 23.04px;
  }

    .theme-myparker #okta-login-container {
      width: auto;
    }
}
.theme-myparker #okta-sign-in .o-form .input-fix input {
  line-height: 21px;
  font-size: 16px;
}

.theme-myparker #okta-sign-in .o-form-button-bar {
  padding: 0 0 45px;
}
.theme-myparker #okta-sign-in.auth-container.main-container .margin-btm-30 {
  margin-bottom: 15px;
}
.theme-myparker #okta-sign-in .o-form label{
  color: #424242;
}
.theme-myparker #okta-sign-in .o-form-head{
  margin: 0 0 24px !important;
}